import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../../../core/reducers';
import {
  FormValidationRegExService,
  LayoutConfigService,
  SubheaderService,
} from '../../../../../../core/_base/layout';
import {
  LayoutUtilsService,
  MessageType,
  TypesUtilsService,
} from '../../../../../../core/_base/crud';
import {
  HeadquarterModel,
  HeadquarterOnServerCreated,
  HeadquartersService,
  HeadquarterUpdated,
  selectHeadquarterById,
  selectLastCreatedHeadquarterId,
} from '../../../../../../core/headquarter';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'kt-headquarter-edit',
  templateUrl: './headquarter-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadquarterEditComponent implements OnInit, OnDestroy {
  headquarter: HeadquarterModel;
  headquarterId$: Observable<number>;
  headquarterConfigurationCode$: Observable<any>;
  oldHeadquarter: HeadquarterModel;
  selectedTab: number = 0;
  loadingSubject = new BehaviorSubject<boolean>(true);
  loading$: Observable<boolean>;
  headquarterForm: FormGroup;
  hasFormErrors: boolean = false;
  availableYears: number[] = [];
  canEdit: boolean = false;
  isHeadquarterEmployee: boolean = false;
  private campaignModule: boolean;
  private staffBonusModule: boolean;
  private focusContractMode: boolean;
  private hideCustomerInfo: boolean;
  private enableSalesDiscount: boolean;

  private componentSubscriptions: Subscription;
  private headerMargin: number;

  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private headquarterFB: FormBuilder,
    public dialog: MatDialog,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private layoutConfigService: LayoutConfigService,
    private headquarterService: HeadquartersService,
    private cdr: ChangeDetectorRef,
    private permissionService: NgxPermissionsService,
    private validate: FormValidationRegExService,
    private location: Location,
  ) {}

  searching = false;
  searchFailed = false;
  formatter = (x: { name: string }) => x.name;
  searchCountry = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this.headquarterService.findCountriesAutocomplete(term).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }),
        ),
      ),
      tap(() => (this.searching = false)),
    )

  ngOnInit() {
    this.permissionService.permissions$.subscribe((permissions) => {
      this.canEdit = !!permissions['ROLE_SUPER_ADMIN'];
      this.isHeadquarterEmployee = !!permissions['ROLE_HEADQUARTER_MANAGER'];
    });
    for (let i = 2019; i > 1945; i--) {
      this.availableYears.push(i);
    }
    this.loading$ = this.loadingSubject.asObservable();
    this.loadingSubject.next(true);

    if (this.isHeadquarterEmployee) {
      this.loadEmployeeHeadquarter();
    } else {
      this.activatedRoute.params.subscribe((params) => {
        const id = params['id'];
        if (id && id > 0) {
          this.store
            .pipe(select(selectHeadquarterById(id)))
            .subscribe((result) => {
              if (!result) {
                this.loadHeadquarterFromService(id);
                return;
              }

              this.loadHeadquarter(result);
            });
        } else {
          const newHeadquarter = new HeadquarterModel();
          newHeadquarter.clear();
          this.loadHeadquarter(newHeadquarter);
        }
      });
    }

    window.onload = () => {
      const style = getComputedStyle(document.getElementById('kt_header'));
      this.headerMargin = parseInt(style.height, 0);
    };
  }

  loadHeadquarter(_headquarter, fromService: boolean = false) {
    if (!_headquarter) {
      this.goBack('');
    }
    this.headquarter = _headquarter;
    this.campaignModule = _headquarter.campaignModule;
    this.staffBonusModule = _headquarter.staffBonusModule;
    this.focusContractMode = _headquarter.focusContractMode;
    this.hideCustomerInfo = _headquarter.hideCustomerInfo;
    this.enableSalesDiscount = _headquarter.enableSalesDiscount;
    this.headquarterId$ = of(_headquarter.id);
    this.headquarterConfigurationCode$ = of(_headquarter.configuration.code);
    this.oldHeadquarter = Object.assign({}, _headquarter);
    this.initHeadquarter();
    if (fromService) {
      this.cdr.detectChanges();
    }
  }

  loadHeadquarterFromService(headquarterId) {
    this.headquarterService
      .getHeadquarterById(headquarterId)
      .subscribe((res) => {
        this.loadHeadquarter(res, true);
      });
  }

  loadEmployeeHeadquarter() {
    this.headquarterService.getEmployeeHeadquarter().subscribe((res) => {
      this.loadHeadquarter(res, true);
    });
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.unsubscribe();
    }
  }

  initHeadquarter() {
    this.createForm();
    const prefix = this.layoutConfigService.getCurrentMainRoute();
    this.loadingSubject.next(false);
    if (!this.headquarter.id) {
      this.subheaderService.setBreadcrumbs([
        { title: 'eCommerce', page: `../${prefix}/ecommerce` },
        { title: 'Headquarters', page: `../${prefix}/ecommerce/headquarters` },
        {
          title: 'Create headquarter',
          page: `../${prefix}/ecommerce/headquarters/add`,
        },
      ]);
      return;
    }
    this.subheaderService.setTitle('Edit headquarter');
    this.subheaderService.setBreadcrumbs([
      { title: 'eCommerce', page: `../${prefix}/ecommerce` },
      { title: 'Headquarters', page: `../${prefix}/ecommerce/headquarters` },
      {
        title: 'Edit headquarter',
        page: `../${prefix}/ecommerce/headquarters/edit`,
        queryParams: { id: this.headquarter.id },
      },
    ]);
  }

  createForm() {
    this.headquarterForm = this.headquarterFB.group({
      name: [this.headquarter.name, [this.validate.name()]],
      googleTagManagerKey: [this.headquarter.googleTagManagerKey, []],
      VAT: [this.headquarter.VAT, [Validators.required]],
      staffBonus: [this.headquarter.staffBonus, [Validators.required]],
      currency: [
        (<any>this.headquarter.country).config.currency,
        [Validators.required],
      ],
      bankIDVerification: [
        this.headquarter.bankIDVerification,
        [Validators.required],
      ],
      onlineBankIDVerification: [
        this.headquarter.onlineBankIDVerification,
        [Validators.required],
      ],
      notAuthorCampaignApproval: [
        this.headquarter.notAuthorCampaignApproval,
        [Validators.required],
      ],
      storeChangingAllowed: [
        this.headquarter.storeChangingAllowed,
        [Validators.required],
      ],
      country: [this.headquarter.country, [Validators.required]],
      enableAnonimization: new FormControl(
        this.headquarter.isAnonymizationEnabled,
      ),
      finishedOrdersAnonimization: [
        this.headquarter.anonymizeFinishedOrdersAfterDays || '',
      ],
      unfinishedOrdersAnonimization: [
        this.headquarter.anonymizeUnfinishedOrdersAfterDays || '',
      ],
    });

    this.headquarterForm
      .get('enableAnonimization')
      .valueChanges.subscribe((val) => {
        if (val) {
          this.headquarterForm.get('finishedOrdersAnonimization').enable();
          this.headquarterForm
            .get('finishedOrdersAnonimization')
            .setValidators(Validators.required);

          this.headquarterForm.get('unfinishedOrdersAnonimization').enable();
          this.headquarterForm
            .get('unfinishedOrdersAnonimization')
            .setValidators(Validators.required);
          this.headquarterForm.updateValueAndValidity();
        } else {
          this.headquarterForm.get('finishedOrdersAnonimization').disable();
          this.headquarterForm.get('unfinishedOrdersAnonimization').disable();
          this.headquarterForm.patchValue({
            finishedOrdersAnonimization: '',
            unfinishedOrdersAnonimization: '',
          });
          this.headquarterForm.updateValueAndValidity();
        }
      });
  }

  goBack(id) {
    this.loadingSubject.next(false);
    const url = `${this.layoutConfigService.getCurrentMainRoute()}/ecommerce/headquarters?id=${id}`;
    this.router.navigateByUrl(url, /* Removed unsupported properties by Angular migration: relativeTo. */ {});
  }

  back() {
    if (!this.isHeadquarterEmployee) {
      this.router.navigate(['/dashboard/headquarter/list']);
    } else {
      this.location.back();
    }
  }

  refreshHeadquarter(isNew: boolean = false, id = 0) {
    this.loadingSubject.next(false);
    let url = this.router.url;
    if (!isNew) {
      this.router.navigate([url], { relativeTo: this.activatedRoute });
      return;
    }

    url = `/dashboard/headquarter/edit/${id}`;
    this.router.navigateByUrl(url, /* Removed unsupported properties by Angular migration: relativeTo. */ {});
  }

  reset() {
    this.headquarter = Object.assign({}, this.oldHeadquarter);
    this.createForm();
    this.hasFormErrors = false;
    this.headquarterForm.markAsPristine();
    this.headquarterForm.markAsUntouched();
    this.headquarterForm.updateValueAndValidity();
  }

  onSumbit(withBack: boolean = false) {
    this.hasFormErrors = false;
    const controls = this.headquarterForm.controls;
    if (this.headquarterForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );

      this.hasFormErrors = true;
      this.selectedTab = 0;
      return;
    }

    // tslint:disable-next-line:prefer-const
    let editedHeadquarter = this.prepareHeadquarter();

    if (editedHeadquarter.id > 0) {
      this.updateHeadquarter(editedHeadquarter, withBack);
      return;
    }

    this.addHeadquarter(editedHeadquarter, withBack);
  }

  prepareHeadquarter(): HeadquarterModel {
    const controls = this.headquarterForm.controls;
    const _headquarter = new HeadquarterModel();
    _headquarter.id = this.headquarter.id;
    _headquarter.name = controls['name'].value;
    // _headquarter.country = controls['country'].value;
    _headquarter.googleTagManagerKey = controls['googleTagManagerKey'].value;
    _headquarter.VAT = controls['VAT'].value;
    _headquarter.staffBonus = controls['staffBonus'].value;
    _headquarter.currency = controls['currency'].value;
    _headquarter.bankIDVerification = controls['bankIDVerification'].value;
    _headquarter.storeChangingAllowed = controls['storeChangingAllowed'].value;
    _headquarter.onlineBankIDVerification = controls['onlineBankIDVerification'].value;
    _headquarter.notAuthorCampaignApproval = controls['notAuthorCampaignApproval'].value;
    _headquarter.countryId = controls['country'].value.id;
    _headquarter.campaignModule = this.campaignModule;
    _headquarter.staffBonusModule = this.staffBonusModule;
    _headquarter.focusContractMode = this.focusContractMode;
    _headquarter.hideCustomerInfo = this.hideCustomerInfo;
    _headquarter.enableSalesDiscount = this.enableSalesDiscount;
    return _headquarter;
  }

  addHeadquarter(_headquarter: HeadquarterModel, withBack: boolean = false) {
    this.loadingSubject.next(true);
    this.store.dispatch(
      new HeadquarterOnServerCreated({ headquarter: _headquarter }),
    );
    this.componentSubscriptions = this.store
      .pipe(delay(1000), select(selectLastCreatedHeadquarterId))
      .subscribe((newId) => {
        if (!newId) {
          return;
        }

        this.loadingSubject.next(false);
        if (withBack) {
          this.goBack(newId);
        } else {
          const message = `New headquarter successfully has been added.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            10000,
            true,
            true,
          );
          this.refreshHeadquarter(true, newId);
        }
      });
  }

  updateHeadquarter(_headquarter: HeadquarterModel, withBack: boolean = false) {
    this.loadingSubject.next(true);

    const updateHeadquarter: Update<HeadquarterModel> = {
      id: _headquarter.id,
      changes: _headquarter,
    };

    this.store.dispatch(
      new HeadquarterUpdated({
        partialHeadquarter: updateHeadquarter,
        headquarter: _headquarter,
      }),
    );

    if (withBack) {
      this.goBack(_headquarter.id);
    } else {
      const message = `Headquarter successfully has been saved.`;
      this.layoutUtilsService.showActionNotification(
        message,
        MessageType.Update,
        10000,
        true,
      );
      this.refreshHeadquarter(false);
    }
  }

  getComponentTitle() {
    let result = 'Create headquarter';
    if (!this.headquarter || !this.headquarter.id) {
      return result;
    }

    result = `Edit headquarter - ${this.headquarter.name}`;
    return result;
  }

  getModule({ name, value }) {
    if (name === 'campaignModule') {
      this.campaignModule = value;
    }
    if (name === 'staffBonusModule') {
      this.staffBonusModule = value;
    }
    if (name === 'focusContractMode') {
      this.focusContractMode = value;
    }
    if (name === 'hideCustomerInfo') {
      this.hideCustomerInfo = value;
    }
    if (name === 'enableSalesDiscount') {
      this.enableSalesDiscount = value;
    }
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
