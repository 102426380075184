import { Injectable } from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {HeadquarterConfiguration} from "../../catalog/_services/provider.service";

@Injectable({
  providedIn: 'root',
})
export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [],
    },
    aside: {
      self: {},
      items: [
        {
          title: 'Dashboard',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/dashboard/main',
          translate: 'MENU.DASHBOARD',
          bullet: 'dot',
          permission: ['ROLE_SUPER_ADMIN'],
        },
        {
          title: 'Catalog',
          root: true,
          icon: 'flaticon2-list-2',
          page: 'catalog/list',
          translate: 'MENU.CATALOG',
          bullet: 'dot',
          permission: [
            'ROLE_ORDER_MANAGER',
            'ROLE_SALES_PERSON',
            'ROLE_HEADQUARTER_MANAGER',
            'ROLE_SUPER_ADMIN',
            'ROLE_FINANCE_ADMIN',
            'ROLE_SENIOR_SALES_LEADER',
            'ROLE_STORE_MANAGER',
            'ROLE_ONLINE_PICKUP',
          ].filter(Boolean),
        },
        {
          section: 'Administration',
          translate: 'MENU.ADMINISTRATION',
        },
        {
          title: 'Users',
          root: true,
          icon: 'flaticon2-user-outline-symbol',
          page: 'users',
          permission: [
            'ROLE_SUPER_ADMIN',
            'ROLE_HEADQUARTER_MANAGER',
            'ROLE_STORE_MANAGER',
            'ROLE_SENIOR_SALES_LEADER',
          ],
        },
        {
          title: 'Employee requests',
          root: true,
          icon: 'flaticon2-user-outline-symbol',
          page: '/dashboard/employee-requests',
          permission: ['ROLE_VODAFONE_DISTRICT_MANAGER'],
        },
        {
          title: 'Staff bonus',
          root: true,
          icon: 'flaticon-price-tag',
          page: '/dashboard/staff-bonus',
          permission: ['ROLE_SUPER_ADMIN','ROLE_HEADQUARTER_MANAGER','ROLE_STORE_MANAGER','ROLE_SALES_PERSON'],
        },
        {
          title: 'Pricing input',
          root: true,
          icon: 'flaticon-price-tag',
          page: '/dashboard/price',
          permission: ['ROLE_SUPER_ADMIN', 'ROLE_HEADQUARTER_MANAGER'],
        },
        {
          title: 'Contract input',
          root: true,
          icon: 'flaticon2-contract',
          page: '/dashboard/contract-input',
          permission: ['ROLE_SUPER_ADMIN', 'ROLE_HEADQUARTER_MANAGER'],
        },
        {
          title: 'Campaigns',
          root: true,
          icon: 'flaticon2-contract',
          page: '/dashboard/campaigns',
          permission: ['ROLE_SUPER_ADMIN', 'ROLE_HEADQUARTER_MANAGER'],
        },
        {
          title: 'Headquarter',
          root: true,
          icon: 'flaticon2-browser-2',
          page: '/dashboard/headquarter/management',
          permission: ['ROLE_HEADQUARTER_MANAGER'],
        },
        {
          title: 'Headquarters',
          root: true,
          icon: 'flaticon2-browser-2',
          page: '/dashboard/headquarter/list',
          permission: [
            'ROLE_SUPER_ADMIN'
          ],
        },
        {
          title: 'Stores',
          root: true,
          icon: 'flaticon2-digital-marketing',
          page: 'store/list',
          permission: [
            'ROLE_STORE_MANAGER',
            'ROLE_HEADQUARTER_MANAGER',
            'ROLE_SUPER_ADMIN',
          ],
        },
        {
          title: 'Orders',
          root: true,
          icon: 'flaticon-business',
          // page: 'order/list',
          translate: 'MENU.ORDERS',
          permission: [
            'ROLE_ORDER_MANAGER',
            'ROLE_HEADQUARTER_MANAGER',
            'ROLE_SUPER_ADMIN',
            'ROLE_FINANCE_ADMIN',
            'ROLE_SENIOR_SALES_LEADER',
            'ROLE_STORE_MANAGER',
            'ROLE_ONLINE_PICKUP',
          ].filter(Boolean),
          submenu: [
            {
              title: 'View',
              page: 'order/list',
              icon: 'flaticon-eye',
              translate: 'MENU.ORDERS_VIEW',
              permission: [
                'ROLE_ORDER_MANAGER',
                'ROLE_SALES_PERSON',
                'ROLE_HEADQUARTER_MANAGER',
                'ROLE_SUPER_ADMIN',
                'ROLE_FINANCE_ADMIN',
                'ROLE_SENIOR_SALES_LEADER',
                'ROLE_STORE_MANAGER',
                'ROLE_ONLINE_PICKUP',
              ],
            },
            {
              title: 'Report',
              page: '/dashboard/order/report',
              icon: 'flaticon-file-2',
              permission: [
                'ROLE_ORDER_MANAGER',
                // 'ROLE_SALES_PERSON',
                'ROLE_HEADQUARTER_MANAGER',
                'ROLE_SUPER_ADMIN',
                'ROLE_FINANCE_ADMIN',
                'ROLE_SENIOR_SALES_LEADER',
              ],
            },
            {
              title: 'Power BI',
              page: '/dashboard/order/power',
              icon: 'flaticon2-poll-symbol',
              permission: ['ROLE_HEADQUARTER_MANAGER', 'ROLE_SUPER_ADMIN'],
            },
          ],
        },
        {
          title: 'Logs',
          bullet: 'dot',
          icon: 'flaticon2-attention',
          submenu: [
            {
              title: 'Authorization',
              page: 'logs/auth',
            },
          ],
          permission: ['ROLE_SUPER_ADMIN'],
        },
        {
          title: 'Import data',
          bullet: 'dot',
          icon: 'flaticon2-attention',
          submenu: [
            {
              title: 'Upload',
              page: 'upload',
            },
          ],
          permission: ['ROLE_SUPER_ADMIN', 'ROLE_HEADQUARTER_MANAGER'],
        },
        {
            title: 'Settings',
            root: true,
            icon: 'flaticon-settings',
            submenu: [
                {
                    title: 'Main',
                    page: '/dashboard/settings/main',
                },
                {
                    title: 'Configuration',
                    page: '/dashboard/settings/config',
                },
                {
                    title: 'Device shortcut config',
                    page: '/dashboard/settings/device-shortcut-config',
                },
                {
                    title: 'Contracts config',
                    page: '/dashboard/settings/contracts-config',
                },
            ],
            permission: ['ROLE_SUPER_ADMIN'],
        },
      ],
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
