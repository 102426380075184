<div class="header-container">
    <mat-card-title class="title" [ngClass]="classes">
        <div [hidden]="noTitle">
            <ng-content *ngIf="!title" select="[ktPortletTitle]"></ng-content>
            <h3 *ngIf="title" [innerHTML]="title" class="title-text"></h3>
        </div>
        <div #refTools [hidden]="hideTools" class="kt-portlet__head-toolbar">
            <ng-content select="[ktPortletTools]"></ng-content>
        </div>
        <ng-content></ng-content>
    </mat-card-title>
    <mat-divider></mat-divider>
</div>

