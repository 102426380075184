import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { QueryParamsModel } from '../../_base/crud';
import {
  EmployeesRequestsModel,
  EmployeesStoreRequestsModel,
} from '../_models/employees-requests.model';
import {
  EmployeesStoreRequestsActions,
  EmployeesStoreRequestsActionTypes,
} from '../_actions/store-employee-requests';

export interface EmployeesStoreRequestsState
  extends EntityState<EmployeesStoreRequestsModel> {
  storeId: number;
  loading: boolean;
  totalCount: number;
  lastCreatedStoreEmployeeId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<EmployeesStoreRequestsModel> =
  createEntityAdapter<EmployeesStoreRequestsModel>();

export const initialEmployeesStoreRequestsState: EmployeesStoreRequestsState =
  adapter.getInitialState({
    loading: false,
    totalCount: 0,
    storeId: undefined,
    lastCreatedStoreEmployeeId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
  });

export function employeesStoreRequestsReducer(
  state = initialEmployeesStoreRequestsState,
  action: EmployeesStoreRequestsActions,
): EmployeesStoreRequestsState {
  switch (action.type) {
    case EmployeesStoreRequestsActionTypes.EmployeesStoreRequestsPageToggleLoading:
      return {
        ...state,
        loading: action.payload.isLoading,
        lastCreatedStoreEmployeeId: undefined,
      };
    case EmployeesStoreRequestsActionTypes.EmployeesStoreRequestsPageRequested:
      return {
        ...state,
        totalCount: 0,
        loading: true,
        storeId: action.payload.storeId,
        lastQuery: action.payload.page,
      };
    case EmployeesStoreRequestsActionTypes.OneEmployeesStoreRequestsDeleted:
      return adapter.removeOne(action.payload, state);
    case EmployeesStoreRequestsActionTypes.EmployeesStoreRequestsPageLoaded:
      return adapter.addMany(action.payload.employeesRequests, {
        ...initialEmployeesStoreRequestsState,
        totalCount: action.payload.totalCount,
        loading: false,
        storeId: state.storeId,
        lastQuery: state.lastQuery,
        showInitWaitingMessage: false,
      });
    default:
      return state;
  }
}
